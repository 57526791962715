@import "../../../../ba_gp_storefront_core/cartridge/scss/default/mixin/breakpoint";
@import "../../../../ba_gp_storefront_core/cartridge/scss/default/variables";
@import "../../../../ba_gp_storefront_core/cartridge/scss/default/blueacorn/var";

/* ============================================================================
Flexbox component
============================================================================ */

$ex: ex-flexbox;

/* ============================================================================
Styles for custom components
============================================================================ */
.page-designer-reference {
    .#{$ex}:hover {
        border: none;
        text-decoration: none;
        color: initial;
    }
}

.#{$ex} {
    min-height: var(--min-height);
    min-width: var(--min-width);
    max-width: var(--max-width-flexbox);
    position: var(--position);
    top: var(--top);
    right: var(--right);
    bottom: var(--bottom);
    left: var(--left);
    transform: var(--transform);
    overflow: hidden;

    @include bp(min-width, $bp-medium) {
        min-height: var(--min-height-md);
        min-width: var(--min-width-md);
        max-width: var(--max-width-md-flexbox);
        position: var(--position-md);
        top: var(--top-md);
        right: var(--right-md);
        bottom: var(--bottom-md);
        left: var(--left-md);
        transform: var(--transform-md);
    }

    &__as-link {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &--region {
        & > .experience-layouts-flexbox {
            flex: initial;
        }
    }
}
